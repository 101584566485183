<template>
  <div class="ParametersSizes">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('PARAMETERS_SURCHARGES_CATEGORY') }}</th>
                <th scope="col">{{ $t('PARAMETERS_SURCHARGES_ITEM') }}</th>
                <th scope="col">{{ $t('PRICE') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('CFG_MENU_DESIGN') }}</td>
                <td>{{ $t('PARAMETERS_NASCAR_CUFFS') }}</td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingNascarCuffs.ITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingNascarCuffs.USA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('CFG_MENU_SIZE') }}</td>
                <td>{{ $t('PARAMETERS_SIZES_STANDARD') }}</td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingSizes.standard.ITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingSizes.standard.USA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('CFG_MENU_SIZE') }}</td>
                <td>{{ $t('PARAMETERS_SIZES_CUSTOM') }}</td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingSizes.customized.ITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input class="text-right" v-model="settingsPricingSizes.customized.USA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <button class="Button Button__edit ParametersPricing__save-button" @click="submitSettings()">
            <ContentSave /> {{ $t('ACTION_SAVE') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentSave from 'vue-material-design-icons/ContentSave.vue'

export default {
  name: 'parametersSizes',
  components: {
    ContentSave
  },
  computed: {
    ...mapGetters(['settingsPricing', 'settingsPricingSizes', 'settingsPricingNascarCuffs'])
  },
  methods: {
    submitSettings() {
      const newSettings = {
        pricing: {
          ...this.settingsPricing,
          customizedSizePrice: {
            standard: Object.keys(this.settingsPricingSizes.standard).reduce(
              (res, e) => ({ ...res, [e]: Number(this.settingsPricingSizes.standard[e]) }),
              {}
            ),
            customized: Object.keys(this.settingsPricingSizes.customized).reduce(
              (res, e) => ({ ...res, [e]: Number(this.settingsPricingSizes.customized[e]) }),
              {}
            )
          },
          nascarCuffs: {
            ITA: Number(this.settingsPricingNascarCuffs.ITA),
            USA: Number(this.settingsPricingNascarCuffs.USA)
          }
        }
      }
      this.$store.dispatch('SETTINGS_UPDATE', newSettings)
    }
  },
  created() {
    this.$store.dispatch('SETTINGS_READ')
  }
}
</script>

<style scoped lang="scss">
.ParametersSizes {
  padding: 30px 0;
}
</style>
